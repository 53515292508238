import React from 'react';
import { Flex, Text, Box, Button, Link } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';

const GetStarted = () => (
  <Box className='body-layout'>
    <Flex flexWrap='wrap' p={['10px', '30px', '50px']} mt={['90px', '110px', '120px']}>
      <Box width={[1, 1, 2 / 5]} p='32px'>
        <Text fontSize={['46px', '58px', '58px']} color='nextonBlue' as='h2' lineHeight='1.1'>
          <Text fontWeight='400' as='span'>
            Find your next{' '}
          </Text>
          <Text fontWeight='700' as='span'>
            team member
          </Text>
        </Text>
      </Box>
      <Box width={[1, 1, 3 / 5]} p='32px'>
        <Text
          fontSize={['34px', '44px', '44px']}
          fontWeight='300'
          color='nextonDarkGrey'
          maxWidth='650px'
          lineHeight='1.3'
          mb='32px'>
          Schedule a call with our Recruiting experts and you'll be set up for success
        </Text>

        <Link href='https://talent.nextonlabs.com/tell-us-more-about-your-business'>
          <Button variant='newMain'>SCHEDULE A CALL</Button>
        </Link>

        <Text fontSize={['18px', '20px', '20px']} mt='40px'>
          Do you want to get hired by a great company?{' '}
          <GatsbyLink to='/talent'>
            <Text as='span' fontWeight='500' color='nextonBlue' className='bright-hover'>
              Find your dream job.
            </Text>
          </GatsbyLink>
        </Text>
      </Box>
    </Flex>
  </Box>
);

export default GetStarted;
