import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import CaseStudyCard from './CaseStudyCard';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 39px;
  width: 100%;
  height: 80%;
  min-height: 870px;

  @media (max-width: 1270px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 700px) {
    height: auto;
  }
`;

const Title = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #202323;
  margin-bottom: 48px;
`;

const AllCaseStudiesButton = styled.button`
  width: fit-content;
  box-sizing: border-box;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  background: #ff6d00;
  border: 2px solid #ffffff;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CaseStudiesGrid = ({ buttonHidden }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCaseStudy(limit: 4, sort: { fields: _createdAt, order: DESC }) {
        nodes {
          id
          excerpt {
            _key
            _rawChildren(resolveReferences: { maxDepth: 10 })
            _type
            children {
              _key
              marks
              _type
              text
            }
            list
            style
          }
          description {
            _key
            _rawChildren(resolveReferences: { maxDepth: 10 })
            _type
            children {
              _key
              _type
              marks
              text
            }
            list
            style
          }
          customImages {
            altText
            id
            imageFourToFive {
              _type
              _key
              asset {
                url
                altText
              }
            }
            imageOneToOne {
              asset {
                url
              }
            }
            imageSixteenToNine {
              asset {
                url
              }
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
  `);

  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    setCaseStudies(data.allSanityCaseStudy.nodes);
  }, [data]);

  return (
    <div>
      <Title>Customer Stories</Title>
      <Grid>
        {caseStudies.map(study => (
          <CaseStudyCard
            key={study?.slug}
            caseStudyProp={study}
            separator={false}
            displayTitle={false}
            compact
            hub={false}
          />
        ))}
      </Grid>
      <ButtonContainer>
        <GatsbyLink to='/resources/customer-stories'>
          <AllCaseStudiesButton hidden={buttonHidden}>
            All Customer Stories <FontAwesomeIcon icon={faArrowRight}> </FontAwesomeIcon>
          </AllCaseStudiesButton>
        </GatsbyLink>
      </ButtonContainer>
    </div>
  );
};

export default CaseStudiesGrid;
