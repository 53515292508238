import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { Link as GatsbyLink } from 'gatsby';

const CaseStudyContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.separator ? '0px 0px 72px' : '0px 0px 0px')};
  gap: 48px;
  width: ${props => (props.compact ? 'auto' : '80vw')};
  height: ${props => (props.compact ? '380px' : '660px')};
  border-bottom: ${props => (props.separator ? '1px solid #D0D0D0' : 'none')};

  @media (max-width: 1430px) {
    height: ${props => (props.compact ? '440px' : '660px')};
  }
  @media (max-width: 920px) {
    width: auto;
  }

  @media (max-width: 702px) {
    height: auto;
  }
`;

const InnerContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 20px 20px -14px rgba(161, 161, 161, 0.160047);
  border-radius: 24px;

  @media (max-width: 1430px) {
    height: 100%;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  @media (max-width: 550px) {
    height: 100%;
  }
`;

const ImageContainerHub = styled.div`
  height: 100%;
  width: 48%;
  background: url(${props => props.image?.imageSixteenToNine?.asset?.url}) no-repeat center center;

  background-size: cover;
  border-radius: 24px 0px 0px 24px;

  @media (max-width: 1278px) {
    height: 100%;
    width: 30%;
    background: url(${props => props.image?.imageFourToFive?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 0px 0px 24px;
  }

  @media (max-width: 1000px) {
    width: 40%;
  }

  @media (max-width: 770px) {
    width: 45%;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: 300px;
    background: url(${props => props.image?.imageSixteenToNine?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 24px 0px 0px;
  }

  @media (max-width: 550px) {
    width: 100%;
    height: 400px;
    background: url(${props => props.image?.imageSixteenToNine?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 24px 0px 0px;
  }

  @media (max-width: 420px) {
    width: 100%;
    height: 400px;
    background: url(${props => props.image?.imageOneToOne?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 24px 0px 0px;
  }
`;

const ImageContainerHomepage = styled.div`
  height: 100%;
  width: 48%;
  background: url(${props => props.image?.imageFourToFive?.asset?.url}) no-repeat center center;
  background-size: cover;
  border-radius: 24px 0px 0px 24px;

  @media (max-width: 1278px) {
    width: 30%;
  }

  @media (max-width: 1000px) {
    width: 40%;
  }

  @media (max-width: 770px) {
    width: 45%;
  }

  @media (max-width: 700px) {
    width: 100%;
    height: 300px;
    background: url(${props => props.image?.imageSixteenToNine?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 24px 0px 0px;
  }

  @media (max-width: 550px) {
    width: 100%;
    height: 400px;
    background: url(${props => props.image?.imageSixteenToNine?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 24px 0px 0px;
  }

  @media (max-width: 420px) {
    width: 100%;
    height: 400px;
    background: url(${props => props.image?.imageOneToOne?.asset?.url}) no-repeat center center;
    background-size: cover;
    border-radius: 24px 24px 0px 0px;
  }
`;

const TextContent = styled.div`
  max-height: 100%;
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #202323;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 23px;
    margin-bottom: 20px;
    @media (max-width: 1270px) {
      margin-bottom: 14px;
    }

    @media (max-width: 1024px) {
      margin-top: 8px;
    }
  }

  h1::after,
  h2::after,
  h3::after {
    content: 'Customer Story';
    display: block;
    background-color: #00a5c815;
    color: #00a5c8;
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    margin: 8px 0px;
    padding: 5px 10px;
    width: fit-content;
  }

  p {
    font-size: ${props => (props.compact ? '18px' : '22px')};
  }

  @media (max-width: 1050px) {
    overflow: hidden;
  }

  @media (max-width: 920px) {
    h1,
    h2,
    h3,
    h4 {
      font-size: 28px;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
    }
  }
`;

const Button = styled.button`
  width: 140px;
  height: 58px;
  background: linear-gradient(90deg, #00a5c8 0%, #01c8ef 100%);
  border-radius: 100px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  @media (max-width: 1270px) {
    margin-top: 20px;
  }

  @media (max-width: 1024px) {
    margin-top: 30px;
  }
`;

const ButtonText = styled.p`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
`;

const ExcerptContainer = styled.div`
  display: flex;
  width: 52%;

  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 50px 70px;
  padding: ${props => (props.compact ? '25px 30px' : '50px 70px')};

  @media (max-width: 1278px) {
    width: 70%;
    padding: 25px 25px;
  }

  @media (max-width: 700px) {
    padding: '0px 0px';
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }

  @media (max-width: 550px) {
    padding: 0px 30px;
    height: 100%;
  }

  @media (max-width: 420px) {
    padding: 30px;
    height: auto;
  }
`;

const Title = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  letter-spacing: -1px;
  color: #202323;
  margin-bottom: 10px;
`;

const CaseStudyCard = ({ caseStudyProp, separator = true, displayTitle = true, compact = false, hub = false }) => {
  const [caseStudy, setCaseStudy] = useState(caseStudyProp);

  useEffect(() => {
    setCaseStudy(caseStudyProp);
  }, [caseStudyProp]);

  return (
    <CaseStudyContainer compact={compact} separator={separator}>
      {caseStudy?.title && displayTitle && <Title>{caseStudy.title}</Title>}
      <InnerContainer>
        {hub ? (
          <ImageContainerHub image={caseStudy?.customImages} title={caseStudy?.customImages?.altText} />
        ) : (
          <ImageContainerHomepage image={caseStudy?.customImages} title={caseStudy?.customImages?.altText} />
        )}
        {caseStudy?.excerpt && (
          <ExcerptContainer compact={compact}>
            <TextContent compact={compact}>
              <PortableText value={caseStudy?.excerpt} />
            </TextContent>
            <GatsbyLink to={`/resources/customer-story/${caseStudy?.slug.current}`}>
              <Button>
                <ButtonText>View</ButtonText>
              </Button>
            </GatsbyLink>
          </ExcerptContainer>
        )}
      </InnerContainer>
    </CaseStudyContainer>
  );
};

export default CaseStudyCard;
