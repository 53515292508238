import React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { Box } from 'rebass';
import MetaData from '../../../components/MetaData';
import Layout from '../../../components/layout/Layout';
import CaseStudiesGrid from '../../../components/resources/CaseStudyGrid';
import GetStarted from '../../../sections/client/GetStarted';

const HeroContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 50px 20px;
  background: linear-gradient(132deg, #00a5c8 48.48%, #9fe6f4 100%);
  box-shadow: 0px 31px 43px -14px rgba(161, 161, 161, 0.46);
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 120px;

  @media (max-width: 666px) {
    flex-direction: column;
    max-height: ${props => (props.textCharacters > 157 ? '11%' : '10%')};
  }
`;

const CaseStudyContainer = styled.div`
  width: 80%;
  margin: auto;
  min-height: 500px;

  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
  }
`;

const CaseStudyTitle = styled.h1`
  color: var(--shade-white-0, #fff);
  font-family: 'Rubik';
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1px;
  margin-top: 0px;

  @media (max-width: 1050px) {
    font-size: 42px;
    font-weight: 700;
  }
`;

const CaseStudySubtitle = styled.h4`
  color: var(--shade-white-0, #fff);
  font-family: 'Rubik';
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: -0.5px;

  @media (max-width: 1050px) {
    font-size: 22px;
    font-weight: 400;
  }
`;

const CaseStudyDescription = styled.h4`
  max-height: 100%;
  margin-bottom: 70px;
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #202323;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media (max-width: 1270px) {
      margin-bottom: 20px;
    }

    @media (max-width: 1024px) {
      margin-top: 20px;
    }
  }

  p {
    font-size: ${props => (props.compact ? '18px' : '22px')};
    margin-top: 30px;
  }

  @media (max-width: 1050px) {
    overflow: hidden;
  }

  @media (max-width: 920px) {
    h1,
    h2,
    h3,
    h4 {
      font-size: 28px;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
    }
  }
`;

const CaseStudyImage = styled.img`
  max-width: 560px;
  max-height: 400px;
  border-radius: 10px;
  margin-left: 20px;
  margin-bottom: 10px;
  float: left;

  @media (max-width: 1050px) {
    max-width: 340px;
    max-height: 286px;
    margin-right: 0px;
    border-radius: 24px;
  }

  @media (max-width: 666px) {
    max-width: 300px;
    max-height: 286px;
    margin-left: 0px;
    border-radius: 24px;
  }
`;
const StyledVideo = styled.iframe`
  width: 700px;
  height: 520px;
  padding-left: 30px;
  order: 1;

  @media (max-width: 1050px) {
    width: 450px;
    height: 350px;
    margin-right: 0px;
  }

  @media (max-width: 666px) {
    width: 300px;
    height: 286px;
    padding-left: 0px;
  }
`;

const BodyContainer = styled.div`
  text-align: center;

  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 666px) {
    padding-top: 50px;
  }
`;

const OtherCaseStudiesContainer = styled.div`
  background: #ebf8fb;
  box-shadow: 0px 41px 60px 11px rgba(161, 161, 161, 0.160047);
  border-radius: 20px;
  width: 100%;
`;

const MainContainer = styled.div`
  padding: 30px 185px;

  @media (max-width: 1600px) {
    padding: 30px 50px;
  }
`;

const AllCaseStudiesButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  width: 258px;
  height: 58px;
  background: #ff6d00;
  border: 2px solid;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 70px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 45%;
  @media (max-width: 666px) {
    width: 90%;
    margin-bottom: 50px;
    margin-top: 200px;
  }
`;

const DownloadButtonContainer = styled.div`
  width: fit-content;
`;

const DownloadButton = styled.button`
  border-radius: 100px;
  background: var(--secondary-orange-500-main, #ff6d00);
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  cursor: pointer;
  color: var(--shade-white-0, #fff);
  font-family: 'Rubik';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
`;

const CaseStudyTemplate = ({ data }) => {
  const caseStudy = data?.sanityCaseStudy;
  const titleLength = caseStudy?.title.replace(/\s/g, '').length;
  const subtitleLength = caseStudy?.subtitle.replace(/\s/g, '').length;
  return (
    <>
      <MetaData
        title={caseStudy?.title}
        description={caseStudy?.metaDescription}
        image={caseStudy?.shareImage?.asset?.url}
      />
      <Layout selected='resources'>
        <Box className='full-width'>
          <HeroContainer textCharacters={titleLength + subtitleLength}>
            <TextContainer>
              <CaseStudyTitle>{caseStudy?.title}</CaseStudyTitle>
              <CaseStudySubtitle>{caseStudy?.subtitle}</CaseStudySubtitle>
              <DownloadButtonContainer>
                <GatsbyLink to={caseStudy?.reportUrl} target='_blank' rel='noopener'>
                  <DownloadButton>Download Report</DownloadButton>
                </GatsbyLink>
              </DownloadButtonContainer>
            </TextContainer>
            <div>
              {caseStudy?.video ? (
                <StyledVideo title='vimeo-player' src={caseStudy?.video} allowFullScreen frameBorder='0' />
              ) : (
                caseStudy?.customImages?.bannerImage && (
                  <CaseStudyImage
                    src={`${caseStudy?.customImages.bannerImage.asset.url.images.fallback.src}`}
                    alt={caseStudy?.customImages.bannerImageAlt}
                  />
                )
              )}
            </div>
          </HeroContainer>

          <CaseStudyContainer>
            <BodyContainer>
              <CaseStudyDescription>
                <PortableText value={caseStudy?.description} />
              </CaseStudyDescription>
            </BodyContainer>
          </CaseStudyContainer>
          <OtherCaseStudiesContainer>
            <MainContainer>
              <CaseStudiesGrid buttonHidden />
            </MainContainer>
          </OtherCaseStudiesContainer>
          <GatsbyLink to='/resources/customer-stories'>
            <ButtonContainer>
              <AllCaseStudiesButton>All Customer Stories</AllCaseStudiesButton>
            </ButtonContainer>
          </GatsbyLink>
          <GetStarted />
        </Box>
      </Layout>
    </>
  );
};

export default CaseStudyTemplate;

export const query = graphql`
  query SanityCaseStudy($slug__current: String!) {
    sanityCaseStudy(slug: { current: { eq: $slug__current } }) {
      title
      subtitle
      slug {
        _key
        _type
        current
        source
      }
      metaDescription
      shareImage {
        asset {
          url
        }
      }
      customImages {
        altText
        id
        imageSixteenToNine {
          asset {
            url
          }
        }
        bannerImage {
          asset {
            url: gatsbyImageData(
              width: 560
              height: 350
              placeholder: NONE
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        bannerImageAlt
      }
      reportUrl
      video
      description {
        _key
        _type
        _rawChildren
        children {
          _type
          _key
          marks
          text
        }
        list
        style
      }
      _createdAt
    }
  }
`;
